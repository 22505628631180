import { Button, Result } from "antd";
import React, { useEffect } from "react";
import { SmileOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { galaxyKids } from "../../assets/styles/globle";
import Header from "../Header";
import { Col, Progress, Row } from "reactstrap";
import Footer from "../FooterPart";

const Thanks = () => {
  const handleSubmit = () => {
    localStorage.setItem("success", 100);
  };
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div
      style={{
        backgroundColor: galaxyKids.backgroundColor,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Header />

      <div
        className="my-4"
        style={{
          minHeight: "100vh",
          width: "100%",
        }}
      >
        <Row className="mt-4 justify-content-center">
          <Col lg={12} className="text-center">
            <Progress color="success" className="my-4 mx-4" value={100} />
            <Result className="thanks-content"
              icon={
                <SmileOutlined
                  style={{ color: galaxyKids.textOrange, fontSize: "60px" }}
                />
              }
              title="Congratulations on becoming part of Quippy's teacher community!"
              subTitle="We look forward to working closely with you to support our students. If you're interested in speaking with us directly, please send an email to teacher@quippy.co."
              extra={
                <Link to="/profile">
                  <Button
                    style={{
                      backgroundColor: galaxyKids.textOrange,
                      color: "white",
                      border: "none",
                    }}
                    size="large"
                    onClick={handleSubmit}
                  >
                    Go to Profile
                  </Button>
                </Link>
              }
            />
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
};

export default Thanks;
