import React, { useEffect, useState } from "react";
import { Card, CardBody, CardText, Col, Progress, Row } from "reactstrap";
import Footer from "../FooterPart";
import Header from "../Header";
import { galaxyKids } from "../../assets/styles/globle";
import AddWorkExperience from "./AddWorkExperience";
import AddEducation from "./AddEducation";
import AddLanguages from "./AddLanguages";
import AddCertificate from "./AddCertificate";
import AddContactDetails from "./AddContactDetails";
import AddIntro from "./AddIntro";
import axios from "axios";
import ReactPlayer from "react-player";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import AddBasicInfo from "./AddBasicInfo";
import EditBasicInfo from "../dashboard/editProfile/EditBasicInfo";
import EditLanguage from "../dashboard/editProfile/EditLanguage";
import EditWorkExperience from "../dashboard/editProfile/EditWorkExperience";
import EditEducation from "../dashboard/editProfile/EditEducation";

const ProfileInformation = () => {
  const [loading, setLoading] = useState(true);
  const [teacher, setTeacher] = useState(null);
  const [basicInfoModalOpen, setBasicInfoModalOpen] = useState(false);
  const [addIntroModalOpen, setAddIntroModalOpen] = useState(false);
  const [addLanguagesModalOpen, setAddLanguagesModalOpen] = useState(false);
  const [addEducationModalOpen, setAddEducationModalOpen] = useState(false);
  const [addWorkExperienceModalOpen, setAddWorkExperienceModalOpen] =
    useState(false);
  const [addCertificateModalOpen, setAddCertificateModalOpen] = useState(false);
  const [addContactDetailsModalOpen, setAddContactDetailsModalOpen] =
    useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  //edit Modal state

  const [isModalVisibleBasic, setIsModalVisibleBasic] = useState(false);
  const [isModalVisiblLang, setIsModalVisibleLang] = useState(false);
  const [isModalVisibleWorkExp, setIsModalVisibleWorkExp] = useState(false);
  const [isModalVisibleEdu, setIsModalVisibleEdu] = useState(false);

  const navigate = useNavigate();

  const handleClick = async () => {
    setLoading(true);
    const token = localStorage.getItem("accessToken");
    const teacherId = localStorage.getItem("teacherId");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/teacher/check-field/${teacherId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success(response.message);
      setLoading(false);
      if (response?.data?.data?.teacherVerified) {
        navigate("/supplemental");
      }
    } catch (error) {
      if (error.response && Array.isArray(error.response.data)) {
        setLoading(false);
        error.response.data.map((d) => {
          message.error(d.message);
          return null;
        });
      } else if (error.response && typeof error.response.data === "object") {
        setLoading(false);
        message.error(error.response.data.message);
      } else {
        setLoading(false);
        message.error("An error occurred");
      }
    }
  };

  useEffect(() => {
    const fetchTeacher = async () => {
      setLoading(true);
      const token = localStorage.getItem("accessToken");
      const teacherId = localStorage.getItem("teacherId");
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/teacher/${teacherId}`
        );
        setTeacher(response?.data?.data);
        setLoading(false);
      } catch (err) {
        console.log("🚀 ~ fetchTeacher ~ err:", err);
        setLoading(false);
      }
    };
    fetchTeacher();
  }, [
    videoFile,
    setVideoFile,
    isModalVisibleBasic,
    addIntroModalOpen,
    isModalVisiblLang,
    isModalVisibleEdu,
    isModalVisibleWorkExp,
    addCertificateModalOpen,
    addContactDetailsModalOpen,
  ]);

  return (
    <>
      <div
        style={{
          backgroundColor: galaxyKids.backgroundColor,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Header loaderProp={loading} />

        <div
          className="my-4"
          style={{
            minHeight: "100vh",
            width: "100%",
          }}
        >
          <Row className="mt-4">
            <Col lg={12} className="text-center">
              <Progress className="my-4 mx-4" value={40} />
              <div style={{ marginRight: '20px', flexShrink: 0, textAlign: 'right' }}>
                <span
                  id="steps"
                  style={{
                    backgroundColor: galaxyKids.backgroundColor,
                    display: 'block',
                    marginBottom: '10px',
                    fontWeight: 'bold',
                    fontSize: '20px',
                  }}
                >
                  Step 2/5
                </span>
              </div>
              <h2 style={{ color: galaxyKids.textOrange, fontSize: "32px" }}>
                Tutor Profile Information
              </h2>
            </Col>
          </Row>

          <Row className="p-3">
            <Col className="text-center mb-3">
              <EditBasicInfo
                imageFile={imageFile}
                setImageFile={setImageFile}
                isModalVisibleBasic={isModalVisibleBasic}
                // setPicture={setPicture}
                setIsModalVisibleBasic={setIsModalVisibleBasic}
              />
              {/* <AddBasicInfo
                imageFile={imageFile}
                setImageFile={setImageFile}
                basicInfoModalOpen={basicInfoModalOpen}
                setBasicInfoModalOpen={setBasicInfoModalOpen}
              /> */}
            </Col>
            <Col className="text-center mb-3">
              <AddIntro
                videoFile={videoFile}
                setVideoFile={setVideoFile}
                addIntroModalOpen={addIntroModalOpen}
                setAddIntroModalOpen={setAddIntroModalOpen}
              />
            </Col>
            <Col className="text-center mb-3">
              {/* <AddLanguages
                addLanguagesModalOpen={addLanguagesModalOpen}
                setAddLanguagesModalOpen={setAddLanguagesModalOpen}
              /> */}
              <EditLanguage
                isModalVisiblLang={isModalVisiblLang}
                setIsModalVisibleLang={setIsModalVisibleLang}
              />
            </Col>
            <Col className="text-center mb-3">
              {/* <AddEducation
                addEducationModalOpen={addEducationModalOpen}
                setAddEducationModalOpen={setAddEducationModalOpen}
              /> */}
              <EditEducation
                isModalVisibleEdu={isModalVisibleEdu}
                setIsModalVisibleEdu={setIsModalVisibleEdu}
              />
            </Col>
            <Col className="text-center mb-3">
              {/* <AddWorkExperience
                addWorkExperienceModalOpen={addWorkExperienceModalOpen}
                setAddWorkExperienceModalOpen={setAddWorkExperienceModalOpen}
              /> */}
              <EditWorkExperience
                isModalVisibleWork={isModalVisibleWorkExp}
                setIsModalVisibleWork={setIsModalVisibleWorkExp}
              />
            </Col>
            <Col className="text-center mb-3">
              <AddCertificate
                addCertificateModalOpen={addCertificateModalOpen}
                setAddCertificateModalOpen={setAddCertificateModalOpen}
              />
            </Col>
            <Col className="text-center mb-3">
              <AddContactDetails
                addContactDetailsModalOpen={addContactDetailsModalOpen}
                setAddContactDetailsModalOpen={setAddContactDetailsModalOpen}
                teacerData= {teacher}
              />
            </Col>
          </Row>

          <Row className="p-3">
            <Col md={7} className="mx-auto">
              <Card className="mb-4">
                <CardBody>
                  <Row>
                    <Col>
                      {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                        <span style={{ color: galaxyKids.textOrange }}>
                          Basic Info
                        </span>
                        <CardText
                          onClick={() => setBasicInfoModalOpen(true)}
                          style={{ color: galaxyKids.textOrange }}
                        >
                          Add Basic Info
                        </CardText>
                      </li> */}
                      <CardText style={{ color: galaxyKids.textColor }}>
                        Click on the profile to upload a new one. Add your
                        display name and location.
                      </CardText>
                      <CardText style={{ color: galaxyKids.textOrange }}>
                        <Button
                          onClick={() => setIsModalVisibleBasic(true)}
                          style={{
                            backgroundColor: galaxyKids.textOrange,
                            border: "none",
                            color: "#fff",
                          }}
                          size="large"
                          className="my-4"
                        >
                          Add Basic Info
                        </Button>
                      </CardText>
                    </Col>
                  </Row>
                  {/* <hr /> */}
                  {teacher?.first_name && teacher?.last_name &&
                  teacher?.location?.coordinates &&
                  teacher?.date_of_birth ? (
                    <>
                      <Row>
                        <Col>
                          <CardText>First Name:</CardText>
                        </Col>
                        <Col>
                          <CardText className="text-muted">
                            {teacher?.first_name}
                          </CardText>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col>
                          <CardText>Last Name:</CardText>
                        </Col>
                        <Col>
                          <CardText className="text-muted">
                            {teacher?.last_name}
                          </CardText>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col>
                          <CardText>Location:</CardText>
                        </Col>
                        <Col>
                          <CardText
                            className="text-muted"
                            style={{ textAlign: "justify" }}
                          >
                            { teacher?.address?.city && `${teacher?.address?.city}, `}
                            { teacher?.address?.street && `${teacher?.address?.street}, ` }{ teacher?.address?.state && `${teacher?.address?.state}, ` }
                            { teacher?.address?.postcode && `${teacher?.address?.postcode}, ` } {`${teacher?.address?.country} `}
                            { teacher?.address?.postcode}
                          </CardText>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col>
                          <CardText>Gender:</CardText>
                        </Col>
                        <Col>
                          <CardText
                            className="text-muted"
                            style={{ textAlign: "justify" }}
                          >
                            {teacher?.gender}
                          </CardText>
                        </Col>
                      </Row>

                      <hr />
                      <Row>
                        <Col>
                          <CardText>Birthday:</CardText>
                        </Col>
                        <Col>
                          <CardText className="text-muted">
                            {teacher?.date_of_birth}
                          </CardText>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col md={7} className="mx-auto">
              <Card className="mb-4">
                <CardBody>
                  <Row>
                    <Col>
                      <CardText style={{ color: galaxyKids.textColor }}>
                        Introduction
                      </CardText>
                      <CardText className="text-secondary">
                        Add your profile video.
                      </CardText>
                      <CardText style={{ color: galaxyKids.textOrange }}>
                        <Button
                          onClick={() => setAddIntroModalOpen(true)}
                          style={{
                            backgroundColor: galaxyKids.textOrange,
                            border: "none",
                            color: "#fff",
                          }}
                          size="large"
                          className="my-4"
                        >
                          Add Introduction
                        </Button>
                      </CardText>
                    </Col>
                  </Row>
                  {/* <hr /> */}
                  <label style={{ color: galaxyKids.textColor }}>Uploaded Video</label>
                  {teacher?.intro_video ? (
                    <ReactPlayer
                      url={
                        `${process.env.REACT_APP_MEDIA_URL}/user/video/` +
                        teacher?.intro_video
                      }
                      playing={false}
                      controls={true}
                      width="100%"
                      height="70%"
                    />
                  ) : (
                    ""
                  )}
                  <hr />
                  <label style={{ color: galaxyKids.textColor }}>Youtube Link Video</label>
                  {teacher?.youtube_video_link ? (
                    <ReactPlayer
                      url={teacher?.youtube_video_link}
                      playing={false}
                      controls={true}
                      width="100%"
                    />
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col md={7} className="mx-auto">
              <Card className="mb-4">
                <CardBody>
                  <Row>
                    <Col>
                      <CardText style={{ color: galaxyKids.textColor }}>
                        Language
                      </CardText>
                      <CardText className="text-secondary">
                        {/* Please select your English accent and other language you speak. */}
                        Indicate the language(s) in which you are a native speaker.
                      </CardText>
                      <CardText style={{ color: galaxyKids.textOrange }}>
                        <Button
                          onClick={() => setIsModalVisibleLang(true)}
                          style={{
                            backgroundColor: galaxyKids.textOrange,
                            border: "none",
                            color: "#fff",
                          }}
                          size="large"
                          className="my-4"
                        >
                          Add Language
                        </Button>
                      </CardText>
                    </Col>
                  </Row>
                </CardBody>
                {teacher?.language_skills?.some(
                  (skill) => skill?.language == "" && skill?.dialect == ""
                ) ? (
                  ""
                ) : (
                  <>
                    {teacher?.language_skills?.map((lan) => {
                      if(lan.language && lan.dialect){
                        return (
                          <CardBody>
                            <Row>
                              <Col>
                                <CardText
                                  style={{ color: galaxyKids.textOrange }}
                                >
                                  Language
                                </CardText>
                              </Col>
                            </Row>
                            <hr />
                            <Row>
                              <Col>
                                <CardText>Language:</CardText>
                              </Col>
                              <Col>
                                <CardText className="text-muted">
                                  {lan?.language}
                                </CardText>
                              </Col>
                            </Row>
                            <hr />
                            <Row>
                              <Col>
                                <CardText>Dialect:</CardText>
                              </Col>
                              <Col>
                                <CardText className="text-muted">
                                  {lan?.dialect}
                                </CardText>
                              </Col>
                            </Row>
                          </CardBody>
                        );
                      }
                    })}
                  </>
                )}
              </Card>
            </Col>

            <Col md={7} className="mx-auto">
              <Card className="mb-4">
                <CardBody>
                  <Row>
                    <Col>
                      <CardText style={{ color: galaxyKids.textColor }}>
                        {/* <ReadOutlined /> */}
                        Education
                      </CardText>
                      <CardText style={{ color: galaxyKids.textOrange }}>
                        <Button
                          onClick={() => setIsModalVisibleEdu(true)}
                          style={{
                            backgroundColor: galaxyKids.textOrange,
                            border: "none",
                            color: "#fff",
                          }}
                          size="large"
                          className="mb-3"
                        >
                          Add Education
                        </Button>
                      </CardText>
                    </Col>
                  </Row>
                </CardBody>

                {teacher &&
                  teacher?.education?.map((education) => {
                    return (
                      <CardBody>
                        <Row>
                          <Col>
                            <CardText style={{ color: galaxyKids.textOrange }}>
                              Education
                            </CardText>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col>
                            <CardText>title:</CardText>
                          </Col>
                          <Col>
                            <CardText className="text-muted">
                              {education.title}
                            </CardText>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col>
                            <CardText>tags:</CardText>
                          </Col>
                          <Col>
                            <CardText className="text-muted">
                              {education?.tags?.join(", ")}
                            </CardText>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col>
                            <CardText>Details:</CardText>
                          </Col>
                          <Col>
                            <CardText className="text-muted">
                              {education?.description}
                            </CardText>
                          </Col>
                        </Row>
                      </CardBody>
                    );
                  })}
              </Card>
            </Col>

            <Col md={7} className="mx-auto">
              <Card className="mb-4">
                <CardBody>
                  <Row>
                    <Col>
                      <CardText style={{ color: galaxyKids.textColor }}>
                        Work Experience
                      </CardText>
                      <CardText style={{ color: galaxyKids.textOrange }}>
                        <Button
                          onClick={() => setIsModalVisibleWorkExp(true)}
                          style={{
                            backgroundColor: galaxyKids.textOrange,
                            border: "none",
                            color: "#fff",
                          }}
                          size="large"
                          className="mb-3"
                        >
                          Add Work Experience
                        </Button>
                      </CardText>
                    </Col>
                  </Row>
                </CardBody>
                {teacher?.work_experience &&
                  teacher?.work_experience?.map((work) => {
                    return (
                      <CardBody>
                        <Row>
                          <Col>
                            <CardText style={{ color: galaxyKids.textOrange }}>
                              Work Experience
                            </CardText>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col>
                            <CardText>Position:</CardText>
                          </Col>
                          <Col>
                            <CardText className="text-muted">
                              {work?.position ? work?.position : ""}
                            </CardText>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col>
                            <CardText>Company Name:</CardText>
                          </Col>
                          <Col>
                            <CardText className="text-muted">
                              {work?.company_name?.join(", ")
                                ? work?.company_name?.join(", ")
                                : ""}
                            </CardText>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col>
                            <CardText>Details:</CardText>
                          </Col>
                          <Col>
                            <CardText className="text-muted">
                              {work?.details}
                            </CardText>
                          </Col>
                        </Row>
                      </CardBody>
                    );
                  })}
              </Card>
            </Col>

            <Col md={7} className="mx-auto">
              <Card className="mb-4">
                <CardBody>
                  <Row>
                    <Col>
                      <CardText style={{ color: galaxyKids.textColor }}>
                        Teaching Certificate
                      </CardText>
                      <CardText className="text-secondary">
                        (Optional) Do you have a background in education? Upload
                        a copy of your certificate and we'll add a badge to your
                        tutor profile. Students won't be able to see your actual
                        certificate!
                      </CardText>
                      <CardText style={{ color: galaxyKids.textOrange }}>
                        <Button
                          onClick={() => setAddCertificateModalOpen(true)}
                          style={{
                            backgroundColor: galaxyKids.textOrange,
                            border: "none",
                            color: "#fff",
                          }}
                          size="large"
                          className="mb-4"
                        >
                          Add Teaching Certificate
                        </Button>
                      </CardText>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col md={7} className="mx-auto">
              <Card className="mb-4">
                <CardBody>
                  <Row>
                    <Col>
                      <CardText style={{ color: galaxyKids.textColor }}>
                        Contact Details
                      </CardText>
                      <CardText style={{ color: galaxyKids.textOrange }}>
                        <Button
                          onClick={() => setAddContactDetailsModalOpen(true)}
                          style={{
                            backgroundColor: galaxyKids.textOrange,
                            border: "none",
                            color: "#fff",
                          }}
                          size="large"
                          className="mb-4"
                        >
                          Add Contact Details
                        </Button>
                      </CardText>
                    </Col>
                  </Row>
                  {teacher?.contact_details?.facebook &&
                  teacher?.contact_details?.instagram &&
                  teacher?.contact_details?.youtube &&
                  teacher?.contact_details?.whatsapp &&
                  teacher?.mobile_number &&
                  teacher?.email ? (
                    <>
                      {/* <Row>
                        <Col>
                          <CardText style={{ color: galaxyKids.textOrange }}>
                            Contact Details
                          </CardText>
                        </Col>
                      </Row> */}
                      <hr />
                      <Row>
                        <Col>
                          <CardText>Facebook:</CardText>
                        </Col>
                        <Col>
                          <CardText className="text-muted">
                            {teacher?.contact_details?.facebook}
                          </CardText>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col>
                          <CardText>Instagram:</CardText>
                        </Col>
                        <Col>
                          <CardText className="text-muted">
                            {teacher?.contact_details?.instagram}
                          </CardText>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col>
                          <CardText>Youtube:</CardText>
                        </Col>
                        <Col>
                          <CardText className="text-muted">
                            {teacher?.contact_details?.youtube}
                          </CardText>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col>
                          <CardText>Whatsapp :</CardText>
                        </Col>
                        <Col>
                          <CardText className="text-muted">
                            {teacher?.contact_details?.whatsapp}
                          </CardText>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col>
                          <CardText>Mobile Number:</CardText>
                        </Col>
                        <Col>
                          <CardText className="text-muted">
                            {teacher?.contact_details.mobile_number}
                          </CardText>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col>
                          <CardText>Email Number:</CardText>
                        </Col>
                        <Col>
                          <CardText className="text-muted">
                            {teacher?.email}
                          </CardText>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
            </Col>

            <Row className="mx-auto w-75">
              <Col className="">
                <Button
                  style={{
                    width: "20%",
                    borderColor: galaxyKids.textOrange,
                    color: galaxyKids.textOrange,
                  }}
                  size="large"
                  onClick={() => navigate("/dashboard")}
                >
                  Previous
                </Button>
              </Col>
              <Col className="text-end">
                <Button
                  style={{
                    width: "20%",
                    backgroundColor: galaxyKids.textOrange,
                    border: "none",
                    color: "#fff",
                  }}
                  size="large"
                  onClick={() => navigate("/supplemental")}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Row>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ProfileInformation;
