import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
} from "reactstrap";
import {
  FaMapMarkerAlt,
  FaLanguage,
  FaClock,
  FaDollarSign,
} from "react-icons/fa";
import { galaxyKids } from "../assets/styles/globle";
import { Rate } from "antd";
import { Link, useNavigate } from "react-router-dom";

const TutorList = ({ tutors }) => {
  const [accessToken, setAccessToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      navigate("/");
    }
    setAccessToken(accessToken);
  }, [navigate]);

  return (
    <div
      style={{
        backgroundColor: galaxyKids.backgroundColor,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Container>
        <Row>
          {tutors?.map((tutor, index) => (
            <Col md={4} key={index} className="mb-4">
              <Card
                style={{
                  border: "2px solid",
                  borderColor: galaxyKids.borderColor,
                }}
                className="my-3 d-flex flex-column h-100 hover-shadow"
              >
                <Row noGutters>
                  <Col xs={12} md={12} lg={6} className="text-center">
                    <Link to={`/tutor/${tutor._id}`}>
                      <img
                        src={
                          `${process.env.REACT_APP_MEDIA_URL}/user/image/` + tutor.picture
                        }
                        alt={tutor.name}
                        className="rounded-circle mt-3 p-2"
                        style={{
                          width: "150px",
                          height: "150px",
                        }}
                      />
                    </Link>
                    <CardTitle
                      style={{ color: galaxyKids.textOrange }}
                      className="mt-3"
                      tag="h5"
                    >
                      {tutor?.first_name} {tutor?.last_name}
                    </CardTitle>
                    <CardSubtitle
                      className="mb-2 text-muted"
                      style={{ fontSize: "12px" }}
                    >
                      {tutor?.label}
                    </CardSubtitle>
                  </Col>
                  <Col md={12} lg={6}>
                    <CardBody>
                      <CardSubtitle
                        className="my-3 text-muted"
                        style={{ fontSize: "12px", height: "24px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                        title={`
                          ${tutor?.address?.street || ''}, 
                          ${tutor?.address?.city || ''}, 
                          ${tutor?.address?.state || ''}, 
                          ${tutor?.address?.postcode || ''}, 
                          ${tutor?.address?.country || ''}
                        `}
                      >
                        <FaMapMarkerAlt
                          className="mr-2"
                          style={{
                            color: galaxyKids.iconColor,
                            width: "20px",
                            height: "20px",
                          }}
                        />
                        &nbsp;&nbsp;
                        {tutor?.address?.street && `${tutor?.address?.street}, `}
                        {tutor?.address?.city && `${tutor?.address?.city}, `}
                        {tutor?.address?.state && `${tutor?.address?.state}, `}
                        {tutor?.address?.postcode && `${tutor?.address?.postcode}, `}
                        {tutor?.address?.country}
                      </CardSubtitle>
                      <CardSubtitle
                        className="my-3"
                        style={{ fontSize: "12px", height: "24px" }}
                      >
                        <FaLanguage
                          className="mr-2"
                          style={{
                            color: galaxyKids.iconColor,
                            width: "20px",
                            height: "20px",
                          }}
                        />
                        &nbsp; &nbsp;
                        {tutor?.language_skills && tutor?.language_skills
                          .map((language, index) => language?.language)
                          .join(", ")}
                      </CardSubtitle>
                      <CardSubtitle
                        className="my-3"
                        style={{ fontSize: "12px", height: "24px" }}
                      >
                        <FaClock
                          className="mr-2"
                          style={{
                            color: galaxyKids.iconColor,
                            width: "20px",
                            height: "20px",
                          }}
                        />
                        &nbsp;&nbsp;
                        {tutor.availability_time}
                      </CardSubtitle>
                      <CardSubtitle className="my-3">
                        <FaDollarSign
                          className="mr-2"
                          style={{
                            color: galaxyKids.iconColor,
                            width: "20px",
                            height: "20px",
                          }}
                        />
                        &nbsp;&nbsp;
                        {tutor.pricing}
                      </CardSubtitle>
                      <div className="mb-2">
                        <Rate disabled allowHalf value={tutor.rating} />
                      </div>
                      <span
                        className="ml-2"
                        style={{ color: galaxyKids.textColor }}
                      >
                        {tutor.rating} Reviews
                      </span>
                    </CardBody>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CardText className="px-3" style={{ textAlign: "justify" }}>
                      {tutor.short_details}
                    </CardText>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default TutorList;
