import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import Header from "../Header";
import Footer from "../FooterPart";
import { galaxyKids } from "../../assets/styles/globle";
import { Select, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import TutorProfile from "./TutorProfile";

const { Option } = Select;

const TutorPage = () => {
  const [formData, setFormData] = useState({
    language: "",
    location: "",
    availability: "",
    pricing: "",
    ratings: "",
    contactDetail: "",
  });
  const [tutors, setTutors] = useState([]);
  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = () => {
    console.log("formData", formData);
  };
  
  return (
    <div
      style={{
        backgroundColor: galaxyKids.backgroundColor,
        width: "100vw",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Header />

      <div  style={{ paddingTop: '100px' }}>
        <Row className="mt-4">
          <Col lg={12} className="text-center">
            <h2 style={{ color: galaxyKids.textOrange, fontSize: "32px" }}>
              Learn Language with the best Tutors online here
            </h2>
          </Col>
        </Row>

        <Row
          className="mx-4"
          style={{
            backgroundColor: galaxyKids.backgroundColor,
          }}
        >
          <TutorProfile />
        </Row>
        <Footer />
      </div>
    </div>
  );
};

export default TutorPage;
