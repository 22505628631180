// src/SettingContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { fetchSettings } from './api';

const SettingContext = createContext();

export const CountryProvider = ({ children }) => {
  const [countries, setSettings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getSettings = async () => {
      const settingsData = await fetchSettings();
      setSettings(settingsData);
      setLoading(false);
    };
    getSettings();
  }, []);

  return (
    <SettingContext.Provider value={{ countries, loading }}>
      {children}
    </SettingContext.Provider>
  );
};

export const useSettings = () => {
  return useContext(SettingContext);
};
