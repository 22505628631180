import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Button } from "antd";
import Header from "./Header";
import { galaxyKids } from "../assets/styles/globle";
import Footer from "./FooterPart";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Divider, message } from "antd";
import { toast } from "react-toastify";
import { useLoadScript } from '@react-google-maps/api';
import Select from 'react-select';
import GoogleLoginButton from './GoogleLoginButton';
import { useSettings } from '../context/CountryContext';

const libraries = ['places'];

const Register = () => {

  const languageOptions = [];
  const { countries, loading } = useSettings();
  countries?.data?.languages.map((data) => {
    languageOptions.push({ value: data, label: data });
  })

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      navigate("/");
    }
    setAccessToken(accessToken);
  }, [navigate]);


  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  });

  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [coordinates, setCoordinates] = useState({ lng: null, lat: null });
  const [address, setAddress] = useState({});

  const autocompleteServiceRef = useRef(null);
  const placesServiceRef = useRef(null);

  useEffect(() => {
    if (isLoaded && !autocompleteServiceRef.current) {
      autocompleteServiceRef.current = new window.google.maps.places.AutocompleteService();
      placesServiceRef.current = new window.google.maps.places.PlacesService(document.createElement('div'));
    }
  }, [isLoaded]);

  useEffect(() => {
    if (!isLoaded || inputValue === '') return;

    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input: inputValue }, (predictions, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
        setOptions(predictions.map((prediction) => ({
          value: prediction.place_id,
          label: prediction.description,
        })));
      }
    });
  }, [isLoaded, inputValue]);

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleChange = (option) => {
    setSelectedOption(option);
    if (option) {
      placesServiceRef.current.getDetails({ placeId: option.value }, (place, status) => {
        //setAddress
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setCoordinates({
            lng: place.geometry.location.lng(),
            lat: place.geometry.location.lat(),
          });

          const addressComponents = place.address_components;
          const formatComponent = (component) => component ? `${component}, ` : '';

          const neighborhood = getComponent(addressComponents, 'neighborhood');
          const sublocalityLevel2 = getComponent(addressComponents, 'sublocality_level_2');
          const sublocalityLevel1 = getComponent(addressComponents, 'sublocality_level_1');
          // Remove trailing comma and whitespace if present
          const formattedStreetAddress = `${formatComponent(neighborhood)}${formatComponent(sublocalityLevel2)}${sublocalityLevel1}`.trim().replace(/,\s*$/, '');

          const address = {
            street: formattedStreetAddress,
            city: getComponent(addressComponents, 'locality'),
            state: getComponent(addressComponents, 'administrative_area_level_1'),
            country: getComponent(addressComponents, 'country'),
            postcode: getComponent(addressComponents, 'postal_code')
          };
          setAddress(address);
        }
      });
    }
  };

  const getComponent = (components, type) => {
    const component = components.find(component => component.types.includes(type));
    return component ? component.long_name : '';
  };

  if (loadError) return <div>Error loading wait...</div>;
  if (!isLoaded) return (<div>loading wait...</div>);

  const handleSubmit = async (values) => {
    const formattedCoordinates = [
      parseFloat(coordinates?.lng).toFixed(4),
      parseFloat(coordinates?.lat).toFixed(4),
    ];
    values.location = { coordinates: formattedCoordinates, type: "Point" };
    values.address = address;
    values.isVerified = false;
    const data = {
      ...values,
      language_skills: [
        {
          language: values.language.label,
          dialect: "",
        },
      ],
    };
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/teacher/register`,
        data
      );
      const accessToken = response?.data?.data?.accessToken;
      const teacherId = response?.data?.data?.teacher?._id;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("teacherId", teacherId);
      toast.success(response?.data?.message);
      
      // Make the additional API call
      const requestResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/request`,
        {
          teacher_id: teacherId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Request response:', requestResponse.data);
  
      navigate("/dashboard");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  

  const handleSuccess = (response) => {
    if(response?.data?.statusCode === 200){
      localStorage.setItem("accessToken", response?.data?.data?.accessToken);
      localStorage.setItem("teacherId", response?.data?.data?.teacher?._id);
      if(response?.data?.data?.teacher?.isVerified){
        navigate("/");
      }else{
        navigate("/profileinformation");
      }
    }
  };

  const handleError = (error) => {
    //console.error('Login failed:', error);
    message.error(error?.response?.data?.message);
  };

  return (
    <div className="container-fluid">
      <Header />
      <div
        className="row no-gutter"
        style={{ backgroundColor: galaxyKids.backgroundColor }}
      >
        <div className="col-md-6 d-none d-md-flex bg-image">
          <img
            src={require("../assets/images/register.png")}
            alt="Your Image"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
        <div className="col-md-6 py-4">
          <div className="login d-flex align-items-center justify-content-center py-4">
            <div className="container py-4">
              <div className="row py-4">
                <div className="col-lg-10  mx-auto py-4">
                  <h2
                    style={{
                      color: galaxyKids.textOrange,
                      fontWeight: "500",
                      textAlign: "center",
                      fontSize: "32px",
                    }}
                    className="py-4"
                  >
                    Start teaching on Quippy Platform
                  </h2>
                  <Form
                    form={form}
                    onFinish={handleSubmit}
                    initialValues={{
                      name: "",
                      first_name: "",
                      last_name: "",
                      email: "",
                      password: "",
                      language: "",
                      location: "",
                    }}
                  >
                    {/* <Form.Item
                      name="name"
                      rules={[
                        { required: true, message: "Please enter your name" },
                      ]}
                    >
                      <Input
                        className="shadow p-3 bg-white rounded placeholder-gray"
                        placeholder="Enter Your Name"
                      />
                    </Form.Item> */}
                    <Form.Item
                      name="first_name"
                      rules={[
                        { required: true, message: "Please enter your firstname" },
                      ]}
                    >
                      <Input
                        className="shadow p-3 bg-white rounded placeholder-gray"
                        placeholder="Enter Your First Name"
                      />
                    </Form.Item>
                    <Form.Item
                      name="last_name"
                      rules={[
                        { required: true, message: "Please enter your lastname" },
                      ]}
                    >
                      <Input
                        className="shadow p-3 bg-white rounded placeholder-gray"
                        placeholder="Enter Your Last Name"
                      />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      rules={[
                        { required: true, message: "Please enter your email" },
                        {
                          type: "email",
                          message: "Please enter a valid email",
                        },
                      ]}
                    >
                      <Input
                        className="shadow p-3 bg-white rounded"
                        placeholder="Enter Your Email"
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your password",
                        },
                      ]}
                    >
                      <Input.Password
                        className="shadow p-3 bg-white rounded"
                        placeholder="Enter Your Password"
                      />
                    </Form.Item>
                    <Form.Item
                      name="confirmPassword"
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The two passwords that you entered do not match"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        className="shadow p-3 bg-white rounded"
                        placeholder="Confirm Password"
                      />
                    </Form.Item>
                    <Form.Item
                      name="language"
                      rules={[
                        {
                          required: true,
                          message: "Please select your language skills",
                        },
                      ]}
                    >
                      {/* <Input
                        className="shadow p-3 bg-white rounded"
                        placeholder="Select Your Language skills"
                      /> */}
                      <Select
                        className="shadow p-3 bg-white rounded"
                        value={selectedOption}
                        options={languageOptions}
                        placeholder="Please select your Native language"
                      />
                    </Form.Item>
                    <Form.Item
                      name="location"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter latitude and longitude (Eg. 0.000000 , 0.000000)",
                        },
                      ]}
                    >
                      {/* <Input
                        className="shadow p-3 bg-white rounded"
                        placeholder="Please Enter latitude and longitude (Eg. 0.000000 , 0.000000)"
                      /> */}
                      <Select
                      className="shadow p-3 bg-white rounded"
                        value={selectedOption}
                        onInputChange={handleInputChange}
                        onChange={handleChange}
                        options={options}
                        placeholder="Search for places..."
                      />
                    </Form.Item>
                    <div className="mb-3">
                      <Divider plain>OR</Divider>
                    </div>
                    <div className="d-flex justify-content-center">
                      
                      <div
                        style={{ height: "auto" }}
                        className="text-dark bg-white border-0 shadow mb-3 bg-white rounded"
                      >
                        <GoogleLoginButton onSuccess={handleSuccess} onError={handleError} />
                      </div>
                    </div>
                    <p className="d-flex justify-content-center">
                      By Registering, you agree to our&nbsp;
                      <span style={{ color: galaxyKids.textColor }}>
                        User Agreement
                      </span>
                      &nbsp; And&nbsp;
                      <span style={{ color: galaxyKids.textColor }}>
                        Privacy Policy
                      </span>
                    </p>
                    <div className="d-flex justify-content-center">
                      <Button
                        htmlType="submit"
                        className="shadow p-3 rounded"
                        style={{
                          backgroundColor: galaxyKids.textOrange,
                          color: "#ddd",
                          border: "none",
                          width: "50%",
                          height: "50px",
                        }}
                      >
                        CREATE ACCOUNT
                      </Button>
                    </div>
                    <p className="mt-3 text-center">
                      Already have an Account?{" "}
                      <span style={{ color: galaxyKids.textColor }}>
                        <Link style={{ textDecoration: "none" }} to={"/login"}>
                          Login
                        </Link>
                      </span>
                    </p>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
