import React, { useEffect, useState } from "react";
import { Col, Progress, Row } from "reactstrap";
import Footer from "../FooterPart";
import Header from "../Header";
import { useLocation, useNavigate } from "react-router-dom";
import { galaxyKids } from "../../assets/styles/globle";
import axios from "axios";
import ReactPlayer from "react-player";
import { Button } from "antd";

const Supplemental = () => {
  const [supplementDetails, setSupplementDetails] = useState(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // useEffect(() => {
  //   const fetchTeacher = async () => {
  //     const token = localStorage.getItem("accessToken");
  //     const teacherId = localStorage.getItem("teacherId");
  //     try {
  //       const headers = {
  //         Authorization: `Bearer ${token}`,
  //       };
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_API_URL}/teacher/${teacherId}`,
  //         { headers }
  //       );
  //       //setTeacher(response?.data?.data);
  //     } catch (err) {
  //       console.log("🚀 ~ fetchTeacher ~ err:", err);
  //     }
  //   };
  //   fetchTeacher();
  // }, []);

  useEffect(() => {
    const fetchSupplementDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/supplement`
        );
        setSupplementDetails(response.data.data);
      } catch (err) {
        console.log("🚀 ~ fetchSupplementDetails ~ err:", err);
      }
    };
    fetchSupplementDetails();
  }, []);

  const renderMedia = (file) => {
    if (!file) return null;
    const fileExtension = file.split('.').pop().toLowerCase();
    const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
    const isVideo = ['mp4', 'avi', 'mov', 'wmv'].includes(fileExtension);

    if (isImage) {
      return (
        <img
          src={`${process.env.REACT_APP_MEDIA_URL}/supplement/${file}`}
          alt="Supplemental"
          style={{ maxWidth: '50%', height: 'auto', display: 'block', margin: '0 auto' }}
        />
      );
    }

    if (isVideo) {
      return (
        <ReactPlayer
          url={`${process.env.REACT_APP_MEDIA_URL}/supplement/${file}`}
          playing={false}
          controls={true}
          width="50%"
          height='auto'
          style={{ display: 'block', margin: '0 auto' }}
        />
      );
    }

    return null;
  };

  return (
    <>
      <div
        style={{
          backgroundColor: galaxyKids.backgroundColor,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Header />

        <div
          className="my-4"
          style={{
            minHeight: "100vh",
            width: "100%",
          }}
        >
          <Row className="mt-4">
            <Col lg={12} className="text-center">
              <Progress className="my-4 mx-4" value={60} />
              <div
                style={{
                  marginRight: "20px",
                  flexShrink: 0,
                  textAlign: "right",
                }}
              >
                <span
                  id="steps"
                  style={{
                    backgroundColor: galaxyKids.backgroundColor,
                    display: "block",
                    marginBottom: "10px",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  Step 3/5
                </span>
              </div>
              <h2 style={{ color: galaxyKids.textOrange, fontSize: "32px" }}>
                Supplemental questions
              </h2>
              <p
                style={{
                  fontSize: "20px",
                }}
                className="p-3 w-75 mx-auto"
              >
                {supplementDetails ? supplementDetails.detail : "Loading..."}
              </p>

              <div
                style={{
                  fontSize: "20px",
                  textAlign: "center"
                }}
                className="p-3 w-75 mx-auto"
              >
                {renderMedia(supplementDetails?.file)}
              </div>
              <ul style={{ fontSize: "20px" }} className="list-group">
                {supplementDetails?.questions.map((question, index) => (
                  <li key={index}>{question}</li>
                ))}
              </ul>
            </Col>
          </Row>
          {/* <Row className="mt-4" justify="center" align="middle">
            <Col lg={12} style={{ display: "flex", justifyContent: "center" }}>
              {teacher?.intro_video ? (
                <ReactPlayer
                  url={`${process.env.REACT_APP_MEDIA_URL}/user/video/${teacher?.intro_video}`}
                  playing={false}
                  controls={true}
                  width="70%"
                  height="70%"
                />
              ) : (
                ""
              )}
            </Col>
          </Row> */}
          <Row className="mx-auto w-75">
            <Col className="mt-0">
              <Button
                style={{
                  width: "20%",
                  borderColor: galaxyKids.textOrange,
                  color: galaxyKids.textOrange,
                }}
                size="large"
                onClick={() => navigate(-1)}
              >
                Previous
              </Button>
            </Col>
            <Col className="text-end">
              <Button
                style={{
                  width: "20%",
                  backgroundColor: galaxyKids.textOrange,
                  border: "none",
                  color: "#fff",
                }}
                size="large"
                onClick={() => navigate("/quiz")}
              >
                Next
              </Button>
            </Col>
          </Row>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Supplemental;
