import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardHeader,
  ListGroup,
  ListGroupItem,
  CardSubtitle,
  Media,
  List,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { galaxyKids } from "../../assets/styles/globle";
import { Button, Divider, Rate } from "antd";
import {
  FaMapMarkerAlt,
  FaLanguage,
  FaClock,
  FaCalendarAlt,
  FaPencilAlt,
  FaRegStickyNote,
} from "react-icons/fa";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";
import axios from "axios";
import { TextWithParagraphs } from "../common/globleData";
import ContactNow from "./ContactNow";
import ContactModal from "./ContactModal";
import SweetAlert2 from 'react-sweetalert2';
import { useNavigate } from 'react-router-dom';

const TutorProfile = () => {
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState({});
  const { id } = useParams(); // Retrieve id from URL
  const [teacher, setTeacher] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("1");
  const [modalOpen, setModalOpen] = useState(false);
  const [isOpenContactModal, setOpenContactModal] = useState(false);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleContactModal = () => {
    setOpenContactModal(!isOpenContactModal);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    const fetchTeacher = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("accessToken");
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/teacher/${id}`,
          { headers }
        );
        console.log("response > > > ",response)
        if (response?.data?.data) {
          setTeacher(response.data.data);
        }else {
          setSwalProps({
              show: true,
              title: 'Basic Usage',
              text: 'Hello World',
              confirmButtonText: 'OK', // Customize button text
              showCancelButton: false, // Hide cancel button
          });
        }
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
        setSwalProps({
          icon: 'error',
          show: true,
          //title: '',
          text: 'Teacher Details Not Found ',
          confirmButtonText: 'Go To Home', // Customize button text
          showCancelButton: false, // Hide cancel button
        });
      }
    };

    fetchTeacher();
  }, [id]);

  const isActiveLink = (link) => {
    return activeTab === link;
  };

  const handleConfirm = () => {
    navigate('/');
  };

  return (
    <div
      style={{
        backgroundColor: galaxyKids.backgroundColor,
        width: "100vw"
      }}
      className="mb-5 mb-md-4"
    >
      <SweetAlert2 {...swalProps} onConfirm={handleConfirm} />
      <Row className="mb-5 mb-md-4">
        <Col xs={12} sm={12} md={12} lg={3} className="mb-5 mb-md-4">
          <Card
            className="rounded shadow h-100"
            style={{
              border: "1px solid",
              borderColor: galaxyKids.textOrange,
            }}
          >
            <CardBody>
              <Media>
                <Media left href="#" className="mr-4">
                  <Media
                    object
                    src={
                      `${process.env.REACT_APP_MEDIA_URL}/user/image/` +
                      teacher?.picture
                    }
                    alt="Tutor Profile"
                    className="w-100"
                  />
                </Media>
                <Media body>
                  <CardTitle
                    tag="h6"
                    className="mt-2 text-center"
                    style={{ color: galaxyKids.textOrange }}
                  >
                    <h3>{teacher?.first_name} {teacher?.last_name}</h3>
                  </CardTitle>
                  <CardSubtitle className="my-4 text-center">
                    <Button
                      style={{
                        color: "white",
                        backgroundColor: galaxyKids.textOrange,
                        border: "none",
                      }}
                      size="large"
                      onClick={() => toggleContactModal()}
                    >
                      Contact Now
                    </Button>
                  </CardSubtitle>
                  <Divider />
                  <CardSubtitle className="my-4 text-center d-flex justify-content-start">
                    <FaMapMarkerAlt
                      className="mr-2"
                      style={{
                        color: galaxyKids.textOrange,
                        width: "30px",
                        height: "30px",
                      }}
                    />
                    &nbsp;
                    { teacher?.address?.street && `${teacher?.address?.street}, ` }
                    { teacher?.address?.city && `${teacher?.address?.city}, `}
                    { teacher?.address?.state && `${teacher?.address?.state}, ` }
                    { teacher?.address?.postcode && `${teacher?.address?.postcode}, ` } {`${teacher?.address?.country} `}
                  </CardSubtitle>
                  <CardSubtitle className="my-3 text-center d-flex justify-content-start">
                    <FaCalendarAlt
                      className="mr-2"
                      style={{
                        color: galaxyKids.textOrange,
                        width: "30px",
                        height: "30px",
                      }}
                    />
                    &nbsp; &nbsp; Since 2021
                  </CardSubtitle>
                  <CardSubtitle className="my-3">
                    <FaLanguage
                      className="mr-2"
                      style={{
                        color: galaxyKids.textOrange,
                        width: "30px",
                        height: "30px",
                      }}
                    />
                    &nbsp; &nbsp;
                    {teacher?.language_skills?.map((language, index) => (
                      <Button className="m-1"
                        key={index}
                        style={{ borderRadius: "20px", marginRight: "10px" }}
                      >
                        {language?.language}
                      </Button>
                    ))}
                  </CardSubtitle>
                  <CardSubtitle className="my-3 text-center d-flex justify-content-start">
                    <FaClock
                      className="mr-2"
                      style={{
                        color: galaxyKids.textOrange,
                        width: "30px",
                        height: "30px",
                      }}
                    />
                    &nbsp; &nbsp; Availability
                  </CardSubtitle>
                  <Card className="my-2 mx-auto">
                    {teacher?.availability?.map((availability, index) => (
                      <ListGroup flush key={index}>
                        <ListGroupItem>{availability}</ListGroupItem>
                      </ListGroup>
                    ))}
                  </Card>
                  <Divider />
                  <CardSubtitle className="my-3 text-center d-flex justify-content-start">
                    <Row className="align-items-center ">
                      <Col xs={6}>
                        <FaPencilAlt
                          style={{
                            color: galaxyKids.textOrange,
                            width: "30px",
                            height: "30px",
                          }}
                        />
                        &nbsp; &nbsp; &nbsp; &nbsp; ${teacher?.pricing}
                      </Col>
                      <Col xs={6}>
                        <Button
                          style={{
                            color: "white",
                            backgroundColor: galaxyKids.textOrange,
                            border: "none",
                            borderRadius: "20px",
                          }}
                          onClick={() => toggleModal()}
                        >
                          Inquire Now
                        </Button>
                      </Col>
                    </Row>
                  </CardSubtitle>
                  <div className="d-flex justify-content-center">
                    <Card
                      className="my-2"
                      outline
                      style={{
                        width: "18rem",
                        borderColor: galaxyKids.textOrange,
                      }}
                    >
                      <CardBody>
                        <Rate disabled allowHalf value={teacher?.rating} />
                        <CardText style={{ color: galaxyKids.textColor }}>
                          {teacher?.rating} Reviews
                        </CardText>
                      </CardBody>
                    </Card>
                  </div>
                  <Divider />
                  <CardSubtitle className="my-3 text-center d-flex justify-content-start">
                    <FaRegStickyNote
                      className="mr-2"
                      style={{
                        color: galaxyKids.textOrange,
                        width: "30px",
                        height: "30px",
                      }}
                    />
                    &nbsp; &nbsp; Important Notes
                  </CardSubtitle>
                  <List>
                    <li>teacher available for 1 more student only.</li>
                    <li>teacher available for 1 more student only.</li>
                    <li>teacher available for 1 more student only.</li>
                  </List>
                </Media>
              </Media>
            </CardBody>
          </Card>
        </Col>

        <Col sm={12} lg={9} className="mb-5 mb-md-5">
          <Card
            style={{ border: "1px solid", borderColor: galaxyKids.textOrange }}
          >
            <CardHeader>
              <Row>
                <Nav tabs>
                  <Col xs={12} md={2}>
                    <NavItem>
                      <NavLink
                        style={{
                          color: "#000",
                          borderBottomColor: isActiveLink("1")
                            ? galaxyKids.textOrange
                            : "",
                        }}
                        onClick={() => toggleTab("1")}
                      >
                        Teacher Profile
                      </NavLink>
                    </NavItem>
                  </Col>
                  <Col xs={12} md={2}>
                    <NavItem>
                      <NavLink
                        style={{
                          color: "#000",
                          borderBottomColor: isActiveLink("2")
                            ? galaxyKids.textOrange
                            : "",
                        }}
                        onClick={() => toggleTab("2")}
                      >
                        Ratings & Reviews
                      </NavLink>
                    </NavItem>
                  </Col>
                </Nav>
              </Row>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col>
                      <CardTitle tag="h5">{teacher?.label}</CardTitle>
                      <CardText>
                        <TextWithParagraphs text={teacher?.details} />
                      </CardText>

                      <CardTitle
                        tag="h4"
                        className="mx-2"
                        style={{
                          borderBottom: "1px solid",
                          borderColor: galaxyKids.textOrange,
                        }}
                      >
                        Teacher Introduction Uploaded Video
                      </CardTitle>
                      <div className="d-flex justify-content-center p-0">
                        <ReactPlayer
                          url={
                            `${process.env.REACT_APP_MEDIA_URL}/user/video/` +
                            teacher?.intro_video
                          }
                          playing={false}
                          controls={true}
                          width="70%"
                          height="70%"
                        />
                      </div>
                      <CardTitle
                        tag="h4"
                        className="mx-2"
                        style={{
                          borderBottom: "1px solid",
                          borderColor: galaxyKids.textOrange,
                        }}
                      >
                        Teacher Introduction Youtube Video
                      </CardTitle>
                      <div className="d-flex justify-content-center p-0">
                        <ReactPlayer
                          url={ 
                            teacher?.youtube_video_link
                          }
                          playing={false}
                          controls={true}
                          width="70%"
                        />
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col>
                      {/* Add your ratings & reviews content here */}
                      <h5>Ratings & Reviews</h5>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ContactNow modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <ContactModal contactdataProp={teacher} modalOpen={isOpenContactModal} setModalOpen={setOpenContactModal} />
    </div>
  );
};

export default TutorProfile;
