import React, { useState } from "react";
import { Button, Nav, NavItem, NavLink, Progress } from "reactstrap";
import styled from "styled-components";
import TutorNavbar from "../common/TutorNavbar";
import { galaxyKids } from "../../assets/styles/globle";
import { Link } from "react-router-dom";
import { Divider } from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  background-color: #f5f5f5;
`;

const SidebarNav = styled(Nav)`
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  width: ${(props) => (props.isOpen ? "250px" : "0")};
  overflow-x: hidden;
  transition: width 0.3s;
`;

const MainContent = styled.div`
  flex: 1;
  padding: 2rem;
`;

const ContentHeader = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const ContentText = styled.p`
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
`;

const ToggleButton = styled(Button)`
  display: block;
  position: fixed;
  top: 1rem;
  left: ${(props) => (props.isOpen ? "250px" : "1rem")};
  z-index: 999;
`;

const GalaxyKidsScreen = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <TutorNavbar />
      <Wrapper>
        <SidebarNav vertical isOpen={isSidebarOpen}>
          <NavItem>
            <label style={{ display: "flex", alignItems: "center" }}>
              <NavLink style={{ color: "#000" }}>
                Become a Quippy Affiliate Teacher
              </NavLink>
              {/* <input type="radio" name="navigation" value="affiliate" /> */}
            </label>
          </NavItem>
          <NavItem>
            <label style={{ display: "flex", alignItems: "center" }}>
              <NavLink style={{ color: "#000" }}>Welcome </NavLink>
              {/* <input type="radio" name="navigation" value="welcome" /> */}
            </label>
          </NavItem>
          <Divider style={{ marginBottom: "0px", marginTop: "0px" }} />
          <NavItem>
            <label style={{ display: "flex", alignItems: "center" }}>
              <Link
                style={{ textDecoration: "none" }}
                to={"/profileinformation"}
              >
                <NavLink style={{ color: "#000" }}>Quippy Profile</NavLink>
              </Link>
              {/* <input type="radio" name="navigation" value="profile" /> */}
            </label>
          </NavItem>
          <NavItem>
            <label style={{ display: "flex", alignItems: "center" }}>
              <Link style={{ textDecoration: "none" }} to={"/supplemental"}>
                <NavLink style={{ color: "#000" }}>
                  Supplemental questions
                </NavLink>
              </Link>
              {/* <input type="radio" name="navigation" value="questions" /> */}
            </label>
          </NavItem>
          {/* <NavItem>
            <label style={{ display: "flex", alignItems: "center" }}>
              <NavLink style={{ color: "#000" }}>Teacher Dashboard</NavLink>
              <input type="radio" name="navigation" value="dashboard" />
            </label>
          </NavItem> */}
          <NavItem>
            <label style={{ display: "flex", alignItems: "center" }}>
              <Link style={{ textDecoration: "none" }} to={"/quiz"}>
                <NavLink style={{ color: "#000" }}>Referral Program</NavLink>
                {/* <input type="radio" name="navigation" value="referral" /> */}
              </Link>
            </label>
          </NavItem>
        </SidebarNav>
        <ToggleButton
          isOpen={isSidebarOpen}
          onClick={toggleSidebar}
          style={{
            position: "absolute",
            left: "174px",
            backgroundColor: galaxyKids.textColor,
          }}
        >
          {isSidebarOpen ? (
            <UnorderedListOutlined />
          ) : (
            <UnorderedListOutlined />
          )}
        </ToggleButton>
        <MainContent style={{ backgroundColor: galaxyKids.backgroundColor }}>
          <Progress className="my-3" value={20} />
          <div style={{ flexShrink: 0, textAlign: 'right' }}>
            <span
              id="steps"
              style={{
                backgroundColor: galaxyKids.backgroundColor,
                display: 'block',
                marginBottom: '10px',
                fontWeight: 'bold',
                fontSize: '20px',
              }}
            >
              Step 1/5
            </span>
          </div>

          <ContentHeader className="mb-5" >Teachers! Welcome to Quippy!</ContentHeader>
          <ContentText style={{
                fontSize: "20px",
              }} >
           <p className="mb-5">We're thrilled to have you join us! Quippy is the #1 AI pronunciation platform for language learners worldwide. We've noticed that many of our users are looking for live teachers in their area, so we've developed a solution to connect them with the best educators.</p>
           <p>Introducing our Matching Platform—completely free of charge for both parents and teachers! This initiative is part of our commitment to enhancing student learning outcomes while fostering a supportive community for teachers and parents.</p>
          </ContentText>
          <Link to={"/profileinformation"}>
            <Button
              style={{
                backgroundColor: galaxyKids.textOrange,
                border: "none",
                color: "#fff",
              }}
            >
              Let's Get Started
            </Button>
          </Link>
        </MainContent>
      </Wrapper>
    </>
  );
};

export default GalaxyKidsScreen;
