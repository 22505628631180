import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Form, Input, message } from "antd";
import { galaxyKids } from "../../assets/styles/globle";
import axios from "axios";
import { useParams } from "react-router-dom";

const ContactNow = (props) => {
  const { modalOpen, setModalOpen } = props;
  const [form] = Form.useForm();
  const [teacher, setTeacher] = useState(null);
  const { id } = useParams(); // Retrieve id from URL
  const [selectedTab, setSelectedTab] = useState('email');

  const selectTab = (tabName) => {
    setSelectedTab(tabName);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    const fetchTeacher = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/teacher/${id}`,
          { headers }
        );
        setTeacher(response?.data?.data);
      } catch (err) {
        console.log("🚀 ~ fetchTeacher ~ err:", err);
      }
    };

    fetchTeacher();
  }, [id]);

  // const handleFormSubmit = async (values) => {
  //   try {
  //     const token = localStorage.getItem("accessToken");
  //     const headers = {
  //       Authorization: `Bearer ${token}`,
  //     };
  //     const data = {
  //       from: values?.email,
  //       to: teacher?.email,
  //       name: values?.name,
  //       teacherName: teacher?.name,
  //       purpose: values?.purpose,
  //       message: values?.message,
  //     };
  //     const response = await axios.post(
  //       "https://prematictech.com/api/v1/teacher/inquiry-now",
  //       data,
  //       {
  //         headers,
  //       }
  //     );

  //     console.log("API response:", response.data);
  //     form.resetFields();
  //     toggleModal();
  //     message.success("Form submitted successfully!");
  //   } catch (error) {
  //     console.error("API error:", error);
  //     message.error("Failed to submit the form. Please try again later.");
  //   }
  // };

  return (
    <div>
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        contentClassName="custom-modal-content"
        backdropClassName="custom-modal-backdrop"
      >
        <ModalHeader
          toggle={toggleModal}
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            color: galaxyKids.textOrange,
            backgroundColor: galaxyKids.backgroundColor,
          }}
        >
          Contact Now
        </ModalHeader>
        <ModalBody
          className="custom-modal-body"
          style={{
            backgroundColor: galaxyKids.backgroundColor,
          }}
        >
          <div className="container">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-5">
                                    
                  <div className="tab-vertical">
                      <ul className="nav nav-tabs" id="myTab3" role="tablist">
                      <div 
                          key={'email'}
                          className={`tab ${selectedTab === 'email' && 'active'}`}
                          onClick={() => selectTab('email')}
                        >
                          <li className="nav-item">
                              <a className={`nav-link ${selectedTab === 'email' && 'active'}`} id="home-vertical-tab" data-toggle="tab" href="#home-vertical" role="tab" aria-controls="home" aria-selected="true">{'email'.charAt(0).toUpperCase() + 'email'.slice(1)}</a>
                          </li>
                      </div>
                      { (props?.contactdataProp?.contact_details && props?.contactdataProp?.contact_details.isPublic) && Object.entries(props?.contactdataProp?.contact_details).map(([key]) => (
                        ['instagram', 'whatsapp', 'line', 'wechat'].includes(key) && ( <div 
                          key={key}
                          className={`tab ${selectedTab === key && 'active'}`}
                          onClick={() => selectTab(key)}
                        >
                          <li className="nav-item">
                              <a className={`nav-link ${selectedTab === key && 'active'}`} id="home-vertical-tab" data-toggle="tab" href="#home-vertical" role="tab" aria-controls="home" aria-selected="true">{key.charAt(0).toUpperCase() + key.slice(1)}</a>
                          </li>
                        </div>
                        )
                      )) }
                      </ul>
                      <div className="tab-content" id="myTabContent3">
                          <div className="tab-pane fade show active" id="home-vertical" role="tabpanel" aria-labelledby="home-vertical-tab">
                            { (selectedTab && selectedTab != 'email') ? ( <div id="barcodeContainer">
                              <img className="barcode" height={200} src={`${process.env.REACT_APP_MEDIA_URL}/user/QR/${props?.contactdataProp?.contact_details[selectedTab]}`} alt={`${selectedTab} barcode`} />
                            </div>) : (<span><h2><a href={`mailto:${props?.contactdataProp?.email}`}>{props?.contactdataProp?.email}</a></h2></span>) 
                            }
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          {/* <div className="vertical-tabs-container" style={{ display: 'flex', alignItems: 'flex-start' }}>
            <div className="vertical-tabs" style={{ marginRight: '20px' }}>
                { props?.contactdataProp?.contact_details && Object.entries(props?.contactdataProp?.contact_details).map(([key]) => (
                  ['instagram', 'whatsapp', 'line', 'wechat'].includes(key) && ( <div 
                    key={key}
                    className={`tab ${selectedTab === key && 'active'}`}
                    onClick={() => selectTab(key)}
                  >
                    <span className="tab-label">{key.charAt(0).toUpperCase() + key.slice(1)}</span>
                  </div>)
                ))}
            </div>

            { selectedTab && ( <div id="barcodeContainer">
              <img className="barcode" height={200} src={`https://prematictech.com/api/user/QR/${props?.contactdataProp?.contact_details[selectedTab]}`} alt={`${selectedTab} barcode`} />
            </div>)
            }
          </div> */}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ContactNow;
