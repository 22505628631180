import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Form, Select, message } from "antd";
import axios from "axios";
import { galaxyKids } from "../../../assets/styles/globle";
import { useSettings } from '../../../context/CountryContext';

const EditLanguage = (props) => {
  const { isModalVisiblLang, setIsModalVisibleLang } = props;
  const [languageForms, setLanguageForms] = useState([{ id: Date.now(), language: "", dialect: "" }]);
  const [form] = Form.useForm();
  const { countries } = useSettings(null);

  const languageOptions = [];
  countries?.data?.languages.map((data) => {
    languageOptions.push({ value: data, label: data });
  })

  useEffect(() => {
    const fetchTeacher = async () => {
      const token = localStorage.getItem("accessToken");
      const teacherId = localStorage.getItem("teacherId");
      try {
        const headers = { Authorization: `Bearer ${token}` };
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/teacher/${teacherId}`, { headers });
        const languageData = response?.data?.data?.language_skills || [];
        const languageFormData = languageData.map((edu, index) => ({
          id: Date.now() + index,
          language: edu.language || "",
          dialect: edu.dialect || "",
        }));
        setLanguageForms(languageFormData);
        form.setFieldsValue({ languageInfo: languageFormData });
      } catch (err) {
        console.log("Error fetching teacher data:", err);
      }
    };

    fetchTeacher();
  }, [form]);

  const handleCancel = () => {
    setIsModalVisibleLang(false);
  };

  const addLanguageForm = () => {
    const newForm = { id: Date.now(), language: "", dialect: "" };
    setLanguageForms([...languageForms, newForm]);
    form.setFieldsValue({ languageInfo: [...languageForms, newForm] });
  };

  const deleteLanguageForm = (id) => {
    const updatedForms = languageForms.filter((form) => form.id !== id);
    setLanguageForms(updatedForms);
    form.setFieldsValue({ languageInfo: updatedForms });
  };

  const handleFormChange = (id, field, value) => {
    const updatedForms = languageForms.map((form) =>
      form.id === id ? { ...form, [field]: value } : form
    );
    setLanguageForms(updatedForms);
    form.setFieldsValue({ languageInfo: updatedForms });
  };

  const onFinish = async () => {
    const token = localStorage.getItem("accessToken");
    const teacherId = localStorage.getItem("teacherId");
    try {
      const formData = languageForms.map(({ id, ...rest }) => rest);
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/teacher/update-details/${teacherId}`,
        { language_skills: formData },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      message.success("Languages saved successfully!");
      form.resetFields();
      handleCancel();
    } catch (error) {
      message.error("Failed to save languages. Please try again.");
    }
  };

  return (
    <Modal
      isOpen={isModalVisiblLang}
      toggle={handleCancel}
      contentClassName="custom-modal-content"
      backdropClassName="custom-modal-backdrop"
    >
      <ModalHeader
        toggle={handleCancel}
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          color: galaxyKids.textOrange,
          backgroundColor: galaxyKids.backgroundColor,
        }}
      >
        Languages Info
      </ModalHeader>
      <ModalBody
        className="custom-modal-body"
        style={{ backgroundColor: galaxyKids.backgroundColor }}
      >
        <Form
          layout="vertical"
          form={form}
          name="languageInfo"
          onFinish={onFinish}
          initialValues={{ languageInfo: languageForms }}
          style={{ backgroundColor: galaxyKids.backgroundColor }}
        >
          {languageForms.map((language, index) => (
            <div key={language.id}>
              <Form.Item
                label="Language"
                name={["languageInfo", index, "language"]}
                rules={[{ required: true, message: "Please select your language!" }]}
                initialValue={language.language}
              >
                <Select
                  placeholder="Select Language"
                  value={language.language}
                  onChange={(value) => handleFormChange(language.id, "language", value)}
                  options={languageOptions}
                >
                </Select>
              </Form.Item>
              <Form.Item
                label="Dialect"
                name={["languageInfo", index, "dialect"]}
                rules={[{ required: true, message: "Please select your dialect!" }]}
                initialValue={language.dialect}
              >
                <Select
                  placeholder="Select Dialect"
                  value={language.dialect}
                  onChange={(value) => handleFormChange(language.id, "dialect", value)}
                >
                  <Select.Option value="General">General</Select.Option>
                  <Select.Option value="Regional">Regional</Select.Option>
                </Select>
              </Form.Item>
              {languageForms.length > 1 && (
                <Button
                  type="danger"
                  onClick={() => deleteLanguageForm(language.id)}
                  style={{ float: "right", color: galaxyKids.textOrange }}
                >
                  Delete
                </Button>
              )}
            </div>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              style={{ color: galaxyKids.textOrange }}
              onClick={addLanguageForm}
              block
            >
              Add Language
            </Button>
          </Form.Item>
        </Form>
      </ModalBody>
      <ModalFooter
        className="custom-modal-footer"
        style={{ backgroundColor: galaxyKids.backgroundColor }}
      >
        <Button
          className="border-0"
          style={{ color: galaxyKids.textOrange, backgroundColor: galaxyKids.backgroundColor }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          className="border-0"
          style={{ backgroundColor: galaxyKids.textOrange, color: "#fff" }}
          onClick={() => form.submit()}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditLanguage;
