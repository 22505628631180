import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "reactstrap";
import Header from "./Header";
import Footer from "./FooterPart";
import { galaxyKids } from "../assets/styles/globle";
import { Select, Button, Pagination } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import TutorList from "./TutorList";
import axios from "axios";
import { json, useNavigate } from "react-router-dom";
import { GoogleMap, useLoadScript, Autocomplete } from '@react-google-maps/api';
import { toast } from "react-toastify";
import { useSettings } from '../context/CountryContext';

const { Option } = Select;

const TutorsScreen = () => {

  //const languageOptions = [];
  const { countries, loading } = useSettings();
  // countries?.data?.languages.map((data) => {
  //   languageOptions.push({ value: data, label: data });
  // })
  
  const navigate = useNavigate();
  const [loader, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [tutors, setTutors] = useState([]);
  const [metaData, setMetaData] = useState([]);
  const [limit, setLimit] = useState(15);
  const [options, setOptions] = useState([]);
  const [address, setAddress] = useState({});

  const generateOptions = () => {
    const newOptions = [];
    //for (let i = 5; i <= 100; i += 10) {
      countries?.data?.radius.map((data) => {
        newOptions.push(<Option key={data} value={data.toString()}>{data} km</Option>);
      })
    //}
    setOptions(newOptions);
  };

  const [formData, setFormData] = useState({
    language: "",
    location: "",
    latitude: null,
    longitude: null,
    radius: "",
    availability: "",
    pricing: "",
    ratings: "",
    contactDetail: "",
    experience: [], // Add experience to formData state
  });

  const autocompleteRef = useRef(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ["places"],
  });

  useEffect(() => {
    generateOptions();

    const savedFilters = JSON.parse(localStorage.getItem("tutorFilters"));
    if (savedFilters) {
      setFormData(savedFilters);
      setCurrentPage(savedFilters.page || 1);
      setLimit(savedFilters.limit || 15);
    }

    fetchTutors();
  }, []);

  useEffect(() => {
    fetchTutors();
  }, [formData, currentPage, limit]);

  const fetchTutors = async () => {
    setLoading(true);

    const params = {
      page: currentPage,
      paginate: limit,
    };

    if (formData.latitude) {
      params.latitude = formData.latitude;
    }
    if (formData.longitude) {
      params.longitude = formData.longitude;
    }
    if (formData.language) {
      params.language = formData.language;
    }
    if (formData.radius) {
      params.radius = formData.radius;
    }
    if (formData.availability) {
      params.availability = formData.availability;
    }
    if (formData.pricing) {
      params.pricing = formData.pricing;
    }
    if (formData.ratings) {
      params.rating = formData.ratings;
    }
    if (formData.contactDetail) {
      params.contact_detail = formData.contactDetail;
    }
    if (formData.location) {
      params.location = JSON.stringify(address);
    }
    if (formData.experience.length > 0) {
      params.experience = formData.experience.join(",");
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/teacher`,
        { params }
      );
      setTutors(response?.data?.data?.teachers);
      setMetaData(response?.data?.data?.metadata);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleInputChange = (name, value) => {
    if (name === 'radius' && formData.location === '') {
      toast.error("Please Select a location");
    } else {
      const updatedFormData = { ...formData, [name]: value };
      setFormData(updatedFormData);
      localStorage.setItem("tutorFilters", JSON.stringify(updatedFormData));
    }
  };

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place.geometry) {
        const updatedFormData = {
          ...formData,
          location: place.formatted_address,
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        };
        setFormData(updatedFormData);
        localStorage.setItem("tutorFilters", JSON.stringify(updatedFormData));

        const addressComponents = place.address_components;
        const formatComponent = (component) => component ? `${component}, ` : '';

        const neighborhood = getComponent(addressComponents, 'neighborhood');
        const sublocalityLevel2 = getComponent(addressComponents, 'sublocality_level_2');
        const sublocalityLevel1 = getComponent(addressComponents, 'sublocality_level_1');
        const formattedStreetAddress = `${formatComponent(neighborhood)}${formatComponent(sublocalityLevel2)}${sublocalityLevel1}`.trim().replace(/,\s*$/, '');

        const address = {
          street: formattedStreetAddress,
          city: getComponent(addressComponents, 'locality'),
          state: getComponent(addressComponents, 'administrative_area_level_1'),
          country: getComponent(addressComponents, 'country'),
          postcode: getComponent(addressComponents, 'postal_code'),
        };
        setAddress(address);
      }
    }
  };

  const getComponent = (components, type) => {
    const component = components.find(component => component.types.includes(type));
    return component ? component.long_name : '';
  };

  const resetFilters = () => {
    setFormData({
      language: "",
      location: "",
      latitude: null,
      longitude: null,
      radius: "",
      availability: "",
      pricing: "",
      ratings: "",
      contactDetail: "",
      experience: [], // Reset experience filter
    });
    setCurrentPage(1); // Reset to first page
    localStorage.removeItem("tutorFilters");
  };

  const handleSubmit = async () => {
    setCurrentPage(1);
    await fetchTutors();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("tutorFilters", JSON.stringify({ ...formData, page }));
  };

  const onShowSizeChange = (current, pageSize) => {
    setLimit(pageSize);
    localStorage.setItem("tutorFilters", JSON.stringify({ ...formData, limit: pageSize }));
  };

  return (
    <div className="teacher-landing"
      style={{
        backgroundColor: galaxyKids.backgroundColor,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Header loaderProp={loader} />

      <section className="filter-section pt-5 mt-5">
        <div className="container-fluid">
            <div className="container-fluid text-center">
              <div className="row mb-3">
                <Col md={3} className="text-center mb-3">
                  <Select
                    placeholder="Language"
                    size="large"
                    style={{
                      width: "100%",
                    }}
                    value={formData.language || undefined}
                    onChange={(value) => handleInputChange("language", value)}
                  >
                    {countries?.data?.languages && countries?.data?.languages.map((data, index) => (
                      <Option value={data}>{data}</Option>
                    ))}
                    {/* <Option value="bahasa">Bahasa</Option>
                    <Option value="chinese">Chinese</Option>
                    <Option value="english">English</Option>
                    <Option value="french">French</Option>
                    <Option value="japanese">Japanese</Option>
                    <Option value="german">German</Option>
                    <Option value="spanish">Spanish</Option>
                    <Option value="thai">Thai</Option> */}
                  </Select>
                </Col>
                <Col md={3} className="text-center mb-3">
                  {isLoaded && !loadError ? (
                    <Autocomplete
                      onLoad={(autocomplete) => {
                        autocompleteRef.current = autocomplete;
                      }}
                      onPlaceChanged={handlePlaceChanged}
                    >
                      <input
                        type="text"
                        placeholder="Location"
                        style={{
                          width: "100%",
                          height: "40px",
                          padding: "0 12px",
                          fontSize: "16px",
                          boxShadow: "0px 4px 6px -1px rgba(0, 0, 0, 0.1)",
                          border: "1px solid #d9d9d9",
                          borderRadius: "4px",
                        }}
                        value={formData.location}
                        onChange={(e) => handleInputChange("location", e.target.value)}
                      />
                    </Autocomplete>
                  ) : (
                    <input
                      type="text"
                      placeholder="Loading..."
                      disabled
                      style={{
                        width: "100%",
                        height: "40px",
                        padding: "0 12px",
                        fontSize: "16px",
                        boxShadow: "0px 4px 6px -1px rgba(0, 0, 0, 0.1)",
                        border: "1px solid #d9d9d9",
                        borderRadius: "4px",
                      }}
                    />
                  )}
                </Col>
                <Col md={3} className="text-center mb-3">
                  <Select
                    placeholder="Radius"
                    size="large"
                    style={{
                      width: "100%",
                    }}
                    value={formData.radius || undefined}
                    onChange={(value) => handleInputChange("radius", value)}
                  >
                    {/* {options} */}
                    {countries?.data?.radius && countries?.data?.radius.map((data, index) => (
                      <Option value={data.toString()}>{data} km</Option>
                    ))}
                  </Select>
                </Col>
                {/* Second Row */}
                <Col md={3} className="text-center mb-3">
                  <Select
                    placeholder="Availability"
                    size="large"
                    style={{
                      width: "100%",
                    }}
                    value={formData.availability || undefined}
                    onChange={(value) => handleInputChange("availability", value)}
                  >
                    {countries?.data?.availability && countries?.data?.availability.map((data, index) => (
                      <Option value={data}>{data}</Option>
                    ))}
                    {/* <Option value="afternoon">Afternoon</Option>
                    <Option value="morning">Morning</Option>
                    <Option value="evening">Evening</Option>
                    <Option value="night">Night</Option> */}
                  </Select>
                </Col>
                <Col md={3} className="text-center mb-3">
                  <Select
                    placeholder="Pricing"
                    size="large"
                    style={{
                      width: "100%",
                    }}
                    value={formData.pricing || undefined}
                    onChange={(value) => handleInputChange("pricing", value)}
                  >
                    {/* <Option value="0-100">0-100 USD</Option>
                    <Option value="100-200">100-200 USD</Option>
                    <Option value="200-300">200-300 USD</Option> */}
                    {countries?.data?.price && countries?.data?.price.map((data, index) => (
                      <Option value={data.toString()}>{data} USD</Option>
                    ))}
                  </Select>
                </Col>
                <Col md={3} className="text-center mb-3">
                  <Select
                    placeholder="Ratings"
                    size="large"
                    style={{
                      width: "100%",
                    }}
                    value={formData.ratings || undefined}
                    onChange={(value) => handleInputChange("ratings", value)}
                  >
                    <Option value="1">1 Star</Option>
                    <Option value="2">2 Stars</Option>
                    <Option value="3">3 Stars</Option>
                    <Option value="4">4 Stars</Option>
                    <Option value="5">5 Stars</Option>
                  </Select>
                </Col>
                <Col md={3} className="text-center mb-3">
                  <input
                    type="text"
                    placeholder="Enter Email Or Phone"
                    style={{
                      width: "100%",
                      height: "40px",
                      padding: "0 12px",
                      fontSize: "16px",
                      boxShadow: "0px 4px 6px -1px rgba(0, 0, 0, 0.1)",
                      border: "1px solid #d9d9d9",
                      borderRadius: "4px",
                    }}
                    value={formData.contactDetail}
                    onChange={(e) => handleInputChange("contactDetail", e.target.value)}
                  />
                </Col>
                <Col md={3} className="text-center mb-3">
                  <Select
                    mode="multiple"
                    placeholder="Experience"
                    size="large"
                    style={{
                      width: "100%",
                      height: "40px",
                      fontSize: "16px",
                    }}
                    value={formData.experience || undefined}
                    onChange={(value) => handleInputChange("experience", value)}
                  >
                    <Option value="Preschool">Preschool</Option>
                    <Option value="K12">K12</Option>
                    <Option value="Adult">Adult</Option>
                  </Select>
                </Col>
              </div>
              <button size="large" style={{ width: '200px', borderRadius: '5px', color: '#FFFF'}} onClick={resetFilters} className="btn btn-info signup-button mb-4"><strong>Reset Filter</strong></button>
            </div>
        </div>
      </section>

      <Row>
        <TutorList tutors={tutors} loading={loader} />
      </Row>

      <Row className="mt-4">
        <Col className="d-flex justify-content-center">
          <Pagination
            current={currentPage}
            total={metaData?.total}
            pageSize={limit}
            onChange={handlePageChange}
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            pageSizeOptions={["15", "30", "45"]}
          />
        </Col>
      </Row>

      <Footer />
    </div>
  );
};

export default TutorsScreen;
