import React, { useEffect, useState } from "react";
import {
  Input,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Collapse,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { galaxyKids } from "../../assets/styles/globle";
import { Button } from "antd";

const TutorNavbar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleNavbar = () => setIsOpen(!isOpen);
  const [accessToken, setAccessToken] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      setAccessToken(true);
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    localStorage.removeItem("accessToken");
    navigate("/login");
  };

  const isActiveLink = (link) => {
    return location.pathname === link;
  };

  const handleRedirect = () => {
    window.location.href = 'https://quippy.co/';
  };

  return (
    <>
    { props.loaderProp && (
        <div className="page-loader-wrapper" v-if="isLoading">
          <div className="loader">
            <div>
              <img src={require("../../assets/images/gLogo.png")} style={{ width: "150px", height: "60px" }} alt="Quippy" />
              <p>Please wait...</p>
            </div>
          </div>
        </div>
    )}
    <Navbar
      className="fixed-top"
      style={{
        backgroundColor: galaxyKids.whiteBackground,
      }}
      light
      expand="md"
    >
      <Container fluid>
        <button className="navbar-toggler" type="button" onClick={toggleNavbar}>
          <span className="navbar-toggler-icon" />
        </button>

        <Collapse isOpen={isOpen} navbar>
          <NavbarBrand
            style={{ color: galaxyKids.textColor, marginRight: "auto" }}
          >
            <Link to={"#"}>
              <img
                src={require("../../assets/images/gLogo.png")}
                style={{ width: "140px", height: "50px" }}
                alt="Logo"
                onClick={handleRedirect}
              />
            </Link>
          </NavbarBrand>

          <Nav className="ml-auto d-flex align-items-center" navbar>
            <NavItem>
              <Button
                className="btn btn-primary"
                onClick={() => navigate("/")}
                style={{ display: 'flex' }}
              >
                HOME
              </Button>
              {/* <Link style={{ textDecoration: "none" }} to={"/"}>
                <NavLink
                  style={{
                    color: isActiveLink("/")
                      ? galaxyKids.textOrange
                      : galaxyKids.textColor,
                  }}
                >
                  HOME
                </NavLink>
              </Link> */}
            </NavItem>
            {accessToken ? (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  nav
                  caret
                  style={{ color: galaxyKids.textColor }}
                >
                  <UserOutlined
                    style={{ fontSize: "25px", color: galaxyKids.textColor }}
                  />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>Account Settings</DropdownItem>
                  <DropdownItem onClick={() => navigate("/profile")}>
                    Your Profile
                  </DropdownItem>

                  <DropdownItem divider />
                  <DropdownItem onClick={() => handleLogout()}>
                    Sign Out
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : (
              ""
            )}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
    </>
  );
};

export default TutorNavbar;
